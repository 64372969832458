import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

const HowItWorksPage = ({ data }) => (
  <Layout page="accommodation-on-tour">
    <Helmet
      title="New Zealand Tour Accommodation with Haka Tours"
      meta={[
        {
          name: 'description', content: `We love what we do, and it seems from our guests' reviews that they love what we do, too!
          We're delighted that we have given so many people the best New Zealand tour they coul ever imagine. `,
        },
        { name: 'og:title', content: 'New Zealand Tour Accommodation with Haka Tours' },
        {
          name: 'og:description', content: `We love what we do, and it seems from our guests' reviews that they love what we do, too!
          We're delighted that we have given so many people the best New Zealand tour they coul ever imagine. `,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/accommodation-on-tour/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/south-island-snow-safari-haka-tours-04.jpg?auto=format">
      <h1 className="c-heading c-heading--h1">Accommodation on tour</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(data.allStaticPages.edges[0].node.content_markdown),
          }}
        />
      </div>
    </section>
  </Layout>
);

HowItWorksPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HowItWorksPage;

export const query = graphql`
  query {
    allStaticPages(
      filter: {
        slug: { eq: "accommodation-on-tour" },
      }
    ) {
      edges {
        node {
          id
          api_id
          slug
          content_markdown
        }
      }
    }
  }
`;
